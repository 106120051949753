import Layout from '../components/layout';
import Seo from '../components/seo';
import * as React from 'react';
import SectionTitle from '../components/sectiontitle/sectionTitle';

const GdprPage = () => {
    return (
        <Layout isFullHeight={false}>
            <div className='container'>
                <section className="section section-gdpr">
                    <SectionTitle title="Polityka prywatności" isH1={true}/>
                    <p className="section-gdpr__content">
                        Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych osobowych w
                        związku z wysłaniem zapytania przez formularz kontaktowy. Podanie danych jest dobrowolne, ale
                        niezbędne do przetworzenia zapytania. Zostałem poinformowany, że przysługuje mi prawo dostępu do
                        swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania. Administratorem
                        danych osobowych jest Alicja Kopczak "POLISY" POŚREDNICTWO UBEZPIECZENIOWE, ul. Franciszkańska 22,
                        37-700 Przemyśl
                    </p>
                </section>
            </div>
        </Layout>
    );
};


/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = ({location}) => <Seo title='Polityka prywatności' location={location}/>;

export default GdprPage;